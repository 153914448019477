<template>
	<form @submit.prevent="checkForm" class="mb-4">
		<div class="row">
			<div class="col-12" v-if="form_message !== ''">
				<ErrorAlert :messageI18n="form_message" />
			</div>
		</div>
		<b-button class="col-3" variant="primary" @click.prevent="alphabetic_sort">{{ $t("action.alphabetic_order") }}</b-button>
		<br><br>
		<nested-details id="invoiceDrag" v-model="selected_bons" />

	</form>
</template>

<script type="text/javascript">
	import TableAction from "@/mixins/TableAction.js"
	import Navigation from "@/mixins/Navigation.js"
	import Config from "@/mixins/Config.js"
    import Invoice from "@/mixins/Invoice.js"
    import _orderBy from 'lodash/orderBy'
	import _sortBy from "lodash/sortBy"

	export default {
		name: "ReorganizeDetails",
		mixins: [Config, TableAction, Navigation, Invoice],
		props: {
			invoicedetails_id: {},
			processing: {},
			modal_loaded: {},
			bons: {},
		},
		data () {
			return {
				form_message: "",
				selected_bons: []
			}
		},
		mounted() {
			this.init_component()
		},
		created () {
		},
		methods: {
			async init_component() {
				let selected_bons = this.deppCloneObj(this.bons).filter(bons => this.invoicedetails_id.indexOf(bons.invoicedetails_id.toString()) > -1)
				selected_bons = _orderBy(selected_bons, ['invoicedetails_group_order', 'invoicedetails_order'], ['asc', 'asc'])

				let tab = []
				let group_order = []
				let i = 0
				for (let index = 0; index < selected_bons.length; index++) {
					const element = selected_bons[index];
					if(element.invoicedetails_horse == null)
					{
						element.invoicedetails_horse = -1
					}

					if(group_order[element.invoicedetails_horse] == undefined) {
						if(element.invoicedetails_group_order == 999) {
							group_order[element.invoicedetails_horse] = i++
						}
						else{
							group_order[element.invoicedetails_horse] = element.invoicedetails_group_order
						}
					}

					if(tab[group_order[element.invoicedetails_horse]] == undefined) {
						tab[group_order[element.invoicedetails_horse]] = {
							"details": [],
							"label": (element.horse) ? element.horse.horse_nom : 'Sans cheval',
							"horse_id" : (element.horse) ? element.horse.horse_id : null
						}
					}
					element.invoicedetails_ht= await this.priceFormat(element.invoicedetails_ht, 'EUR', true)
					tab[group_order[element.invoicedetails_horse]].details.push(element)
				}
				this.selected_bons = tab
			},

			async checkForm() {
				this.$emit("update:processing", true)
				await this.saveOrderInvoiceDetails(this.selected_bons)
				this.$emit("update:processing", false)
				return true
			},

			alphabetic_sort() {
				this.selected_bons = _sortBy(this.selected_bons, 'label')
			}
		},
		computed: {
			
		},
		watch:{
			
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable') ,
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35') ,
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
			draggable: () => import('vuedraggable'),
			NestedDetails: () => import('@/components/Invoice/NestedDetails')
		}
	}
</script>